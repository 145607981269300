import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { GenericPage } from "../../components/mdx/page.js";
import styled from "styled-components";
import { FaPlug } from "react-icons/fa";
import { ButtonPrimary, ButtonSecondary } from "../../components/widgets/button.js";
import { SignUpButton } from "../../components/widgets/sign-up-button";
import { HelpPopup } from "../../components/widgets/help-popup";
import { PricingTable } from "../../components/widgets/pricing-table";
import { Quote } from "../../components/widgets/quote";
export const Flexbox = styled.div`
    display: flex;
    column-gap: ${props => props.theme.spacings.normal};
    margin-top: 3em;
`;
export const SideImage = styled.div`
    flex: 0 0 33%;
`;
export const Anchor = ({
  id
}) => <div id={id} css={`height:0;`} />;
export const _frontmatter = {};
const layoutProps = {
  Flexbox,
  SideImage,
  Anchor,
  _frontmatter
};
const MDXLayout = GenericPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">









    <h1>{`Choose the Right Plan For Your Investment Goals`}</h1>
    <div css={`font-size:larger;`}>
      <br />
      <p><strong parentName="p">{`Try our membership free for 14 days. No credit card required.`}</strong></p>
      <br />
    </div>
    <br />
    <PricingTable mdxType="PricingTable" />
    <Anchor id="tactical-portfolios" mdxType="Anchor" />
    <h2>{`Your New Investment Portfolio Starts Here`}</h2>
    <div>{/*
          The economy ebbs and flows and there is always the next recession down the line. Actively managed portfolios can help limit risk and improve your returns in unfavorable market conditions, but tactical asset allocation requires expertise and is time-consuming on its own. We research tactical portfolios for DIY investors to make your portfolio management process simple.
          */}</div>
    <p>{`TuringTrader’s investment portfolios are tactical portfolios—they’re actively managed and adapt their holdings as market conditions change. For each portfolio, a computer algorithm creates signals to buy or sell assets, based on clear rules and quantitative methods. As a result, tactical portfolios can beat the market with lower risk.`}</p>
    <Quote author="Chung-Kao Hsieh" mdxType="Quote">
      <p>{`Thanks so much, I am glad I found your website! I am thinking about how to deal with potential retirement in the next 3-5-10 years. I like the data/model driven portfolio philosophy of TuringTrader.com—the latest Mean-Kitty is really exciting!`}</p>
    </Quote>
    <Anchor id="premium-portfolios" mdxType="Anchor" />
    <Anchor id="all-stars-portfolios" mdxType="Anchor" />
    <Flexbox mdxType="Flexbox"><SideImage mdxType="SideImage">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "716px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/7219b973a487478b03371d93ff1f1959/6bbf7/portfolio-comparison.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "57.666666666666664%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAA7DAAAOwwHHb6hkAAABdUlEQVQoz31SSW7DMAz0/x9ToD9oL12uDdAkl6bI5iR2vEiyRNFOpqASGc7Ww4C0SQ6HIhPyHp75BqZpUCsFpXUPbUzAMI/YI3B4DjaRQnGuIQRFUWBfFMGWVRVQ1fVNLrctHLvQIJGORNQH3dm/VtH7AyLJbaxFts/xsXrDrsiQiJLrpKEvOPARzG3/fR2XKZXRwSb1mTAGbwosYVqOUZgCRLcNh9OEN6yUAXlCxwd0fITzdKG4rhQm6RjbfAdr6eE08TsplQY6YGkWmKkfeNeGBsMCHADdajimfpsPCataYzIf43n0hNfvF2SbPMiXoBTLu5T7Cp+rd8zSX6ha96dyX+G+wmj5hfl2gUZbWOdOZMxo2IYnkE2S9TCmOfn/KWycxbEDuu7QdxYrSqbpFOssBfl4wHwx8j2EOxTSuGFRINY6C2MstNFgluP1IHJhgni3MZcGtUn8GdceRzqdgkdeaqS5wTrT2OQqEPpzvCc8q5M7/ANfTqFXavwv6QAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "portfolio comparison",
                "title": "portfolio comparison",
                "src": "/static/7219b973a487478b03371d93ff1f1959/6bbf7/portfolio-comparison.png",
                "srcSet": ["/static/7219b973a487478b03371d93ff1f1959/5a46d/portfolio-comparison.png 300w", "/static/7219b973a487478b03371d93ff1f1959/0a47e/portfolio-comparison.png 600w", "/static/7219b973a487478b03371d93ff1f1959/6bbf7/portfolio-comparison.png 716w"],
                "sizes": "(max-width: 716px) 100vw, 716px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </SideImage><div>
        <h3>{`Premium Portfolios`}</h3>
        <p>{`Choose from our broad selection of premium portfolios that cover a wide range of investment objectives and management styles. Whether you’re a beginner or an experienced investor, TuringTrader helps you build diversified portfolios with an optimal mix of liquid stocks and index ETFs. Take advantage of market opportunities and maximize your risk-adjusted returns.`}</p>
        <p>{`With our time-tested portfolios you can manage your investments in as little as 15 minutes per month. Our backtested portfolios have outperformed the market in bullish and bearish times, including the difficult markets of 2008, 2011, 2018, and 2020. Take back control of your investments and enjoy lower-risk portfolios with above benchmark returns.`}</p>
        <p>{`Diversification is the only free lunch in investing. Our `}<a parentName="p" {...{
            "href": "/articles/all-stars-portfolios/"
          }}>{`All-Stars Portfolios`}</a>{` reach the next level of diversification by bundling strategies with different trading styles and rebalancing schedules. This type of diversification makes the portfolios more robust and helps address an even broader range of market situations.`}</p>
      </div></Flexbox>
    <Anchor id="charts-metrics" mdxType="Anchor" />
    <Flexbox mdxType="Flexbox"><div>
        <h3>{`Charts and Performance Metrics`}</h3>
        <p>{`We simulate all our investment algorithms in rigorous backtests, and analyze our model’s behaviors over a historical period. With this method we can learn from the past and prepare for the future. See how we’ve `}<a parentName="p" {...{
            "href": "/articles/backtesting/"
          }}>{`tested model portfolios`}</a>{` reaching back to 2007.`}</p>
        <p>{`Our wealth of background information helps you jumpstart your investment decisions. For all of our portfolios, we provide daily updated charts and metrics so that you can review historical performance, average returns, and drawdowns relative to a benchmark. We explain the portfolio rules and provide guidance on account requirements. For those interested in digging deeper, our `}<a parentName="p" {...{
            "href": "/articles/"
          }}>{`comprehensive background articles`}</a>{` explain our research in detail.`}</p>
      </div><SideImage mdxType="SideImage">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1200px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/a2691b704cdc324f80413bc2085fc14f/eea4a/tt-all-stars-xl_performance-drawdown.jpg",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "56.333333333333336%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAQF/8QAFQEBAQAAAAAAAAAAAAAAAAAAAQL/2gAMAwEAAhADEAAAAdePUVMS0P8A/8QAGBABAAMBAAAAAAAAAAAAAAAAAgADBBD/2gAIAQEAAQUCScR0qZwqx3//xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAwEBPwGH/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAERAv/aAAgBAgEBPwFag3T/xAAdEAABAgcAAAAAAAAAAAAAAAABAAIRICEiMnGB/9oACAEBAAY/ArA3pWbRpEONYyf/xAAbEAACAQUAAAAAAAAAAAAAAAAAAREQITFx0f/aAAgBAQABPyHF3g4UCSDaVnNYP//aAAwDAQACAAMAAAAQRx//xAAXEQEBAQEAAAAAAAAAAAAAAAABEQBB/9oACAEDAQE/EGu4ITf/xAAXEQEBAQEAAAAAAAAAAAAAAAABABFB/9oACAECAQE/EOAJFrf/xAAcEAEBAAICAwAAAAAAAAAAAAABEQAxEKFBYcH/2gAIAQEAAT8QpfRqOg+4ZEF03tLihstTYPLgTiHZc//Z')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "tt all stars xl performance drawdown",
                "title": "tt all stars xl performance drawdown",
                "src": "/static/a2691b704cdc324f80413bc2085fc14f/e5166/tt-all-stars-xl_performance-drawdown.jpg",
                "srcSet": ["/static/a2691b704cdc324f80413bc2085fc14f/f93b5/tt-all-stars-xl_performance-drawdown.jpg 300w", "/static/a2691b704cdc324f80413bc2085fc14f/b4294/tt-all-stars-xl_performance-drawdown.jpg 600w", "/static/a2691b704cdc324f80413bc2085fc14f/e5166/tt-all-stars-xl_performance-drawdown.jpg 1200w", "/static/a2691b704cdc324f80413bc2085fc14f/eea4a/tt-all-stars-xl_performance-drawdown.jpg 1280w"],
                "sizes": "(max-width: 1200px) 100vw, 1200px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </SideImage></Flexbox>
    <Quote author="Thomas Farr" mdxType="Quote">
      <p>{`I do appreciate your transparency, Round Robin's algorithm nailed it! Market Vane is a great addition to the website, thanks for continuing to upgrade TuringTrader.`}</p>
    </Quote>
    <Anchor id="rebalance-alerts" mdxType="Anchor" />
    <Anchor id="position-size-calc" mdxType="Anchor" />
    <Flexbox mdxType="Flexbox"><SideImage mdxType="SideImage">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "632px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/d46b6c5af71742e9c6f612fc2aae6219/084e2/rebalance-email.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "66.33333333333333%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABQElEQVQ4y52TS0vDQBSF8/PdiAv/hooUXXbjQkupaEF38Yk6SdrMI2mazOTxSVJbEVRILhwGLpcz35mHF8aGm3ufKAwIw4iPD4EQgjiWtNU0DX3K818DxhdX+L7PZDLldj5nNrvG9x92hn3k/b1XQ1mWvVRVFV62XqO1RiuFVAolJcYkvcl2kVtn5xxFXmCdwxYF1jp61zZylmWEi4hYS4IoJFaSNE07SqM1xpguQbcqjZKbJNuelO38ig1kg1euCtJAsV4mGCHJ4xWldR2tsxb7n9pE1uJc+W14l79wrC85T2aMkikjMyWtcwZk7ky9cTJnTxxxGJxxIE7ZFydETncjda+L+SJsbEWV2V/OuBn2sKWSxDLeEdVNPdisM8zzHKUUSZpQVfWP3zGIUBvD89MjbyLifZEjlkUnV9aDDD8BHBz1zget8h8AAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "rebalance email",
                "title": "rebalance email",
                "src": "/static/d46b6c5af71742e9c6f612fc2aae6219/084e2/rebalance-email.png",
                "srcSet": ["/static/d46b6c5af71742e9c6f612fc2aae6219/5a46d/rebalance-email.png 300w", "/static/d46b6c5af71742e9c6f612fc2aae6219/0a47e/rebalance-email.png 600w", "/static/d46b6c5af71742e9c6f612fc2aae6219/084e2/rebalance-email.png 632w"],
                "sizes": "(max-width: 632px) 100vw, 632px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </SideImage><div>
        <h3>{`Portfolio Rebalance Alerts`}</h3>
        <p>{`Get offensive or defensive when the time is right.
With our email notifications for Premium and Infinite plans, you’ll know when and how to rebalance your portfolios without keeping track of your rebalancing schedule or checking TuringTrader for updates. We’ll save you the time and research by letting you know when your portfolio needs attention. `}</p>
        <p>{`In our rebalancing dialog, you can simply enter your total account value and TuringTrader will calculate the correct number of shares for you. Even better, it will automatically adjust the account value so the numbers are already very close the next time you visit the site.`}</p>
      </div></Flexbox>
    <Anchor id="dashboard" mdxType="Anchor" />
    <Flexbox mdxType="Flexbox"><div>
        <h3>{`Customizable Dashboard`}</h3>
        <p>{`Monitor your favorite portfolios and access articles in no time. Not only does your dashboard provide quick access to your favorite portfolios, it also lets you review the recent performance of your portfolios and set up your email notifications. We’ll show you how easy it is to `}<a parentName="p" {...{
            "href": "/help/investment-dashboard/"
          }}>{`configure your dashboard`}</a>{` and get the data you need.`}</p>
      </div><SideImage mdxType="SideImage">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "512px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/1add77be7fed3cb5f859005213102b61/01e7c/market-vane.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "37.333333333333336%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAA7DAAAOwwHHb6hkAAABc0lEQVQoz2WRy27UMBiF82o8BI/Ck7DnGVggxEVCqFWnpZ0uYDp0aDtJqpDOJRe7uf52kvlQHIYNi2P/sq1P5xx7xlqMMRgjyAA2uMT8vkEOuLPxTroeqTSH249sr96xWK7YpFses5Aoi9jXe3LJaY3g/QNKiwD9m5cMr1/8BZoJOL5pG6RtKKqKLM/JVE6iU+I8Zlfs0aIRY/DGxUnEQezyA93bV86tjK5H2Q5TF5j7UzY/vjK//s7p8ozzzYyn9Ik4jdGtRqzBs11H1/eTDtDVml7Kae46+qHH9gPSlPThN0r/msVixdX9nLPdCSt1S9CuCWufxrR4ZVlOEY5SmjRXKK3JlSJJU3RROmDnX1Cs5yxuVsx+XvApes+X3WdmzydcPp9TmRovSRLWfoDv+24PgoCHhzWPUUQQhtz9uiOKt5hKY7LIVaCUch3u84RUZzS2pTbN1KF1sYb/dKzhOIv7uAaR1rkflaqMTOe0VlzcEfgHyc4PVZEsjbUAAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "market vane",
                "title": "market vane",
                "src": "/static/1add77be7fed3cb5f859005213102b61/01e7c/market-vane.png",
                "srcSet": ["/static/1add77be7fed3cb5f859005213102b61/5a46d/market-vane.png 300w", "/static/1add77be7fed3cb5f859005213102b61/01e7c/market-vane.png 512w"],
                "sizes": "(max-width: 512px) 100vw, 512px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </SideImage></Flexbox>
    <Anchor id="api" mdxType="Anchor" />
    <Flexbox mdxType="Flexbox"><SideImage mdxType="SideImage">
        <div>{/*<RestApi css={`width:50%;color:${props=>props.theme.colors.decorative};`}/>*/}</div>
        <FaPlug css={`font-size: 7em;color:${props => props.theme.colors.decorative};`} mdxType="FaPlug" />
      </SideImage><div>
        <h3>{`API Access for Automated Trading`}</h3>
        <p>{`For members who want to automate their portfolio maintenance and rebalancing process, our Infinite plan provides `}<a parentName="p" {...{
            "href": "/help/api/"
          }}>{`API access`}</a>{` for your portfolio’s asset allocation. Since most brokerages offer an API to query the account status and place orders, you have the flexibility to create a fully automated system by connecting the two. `}</p>
      </div></Flexbox>
    <Quote author="Jonathan Moore" mdxType="Quote">
      <p>{`I’m sure you hear it a lot, but just wanted to say nice work in TuringTrader.  I love your methods, explanations, and amount of detail and care you put into it.  I was reading your recent update and it seems like you are always improving and refining what is already a great accomplishment.  The extra mile and effort shows.`}</p>
    </Quote>
    <h2>{`Start Your Membership Today`}</h2>
    <br />
    <PricingTable mdxType="PricingTable" />
    <br />
    <h2>{`Frequently Asked Questions`}</h2>
    <ul>
      <li><HelpPopup title="Are my credit card data secure?" mdxType="HelpPopup">
          <p>{`Absolutely. All credit card data resides with our sub-processors, namely `}<em>{`MemberSpace`}</em>{` and `}<em>{`Stripe`}</em>{`. Both are trusted entities, using state-of-the-art encryption and security protocols.`}</p>
        </HelpPopup></li>
      <li><HelpPopup title="What happens after my free trial?" mdxType="HelpPopup">
          <p>{`After 14 days, you receive an email notifying you that your `}<em>{`Premium`}</em>{` trial expired. You can then upgrade to a `}<em>{`Premium`}</em>{` membership or join our free `}<em>{`Basic`}</em>{` plan with just a single mouse click.`}</p>
        </HelpPopup></li>
      <li><HelpPopup title="Do you offer discounts?" mdxType="HelpPopup">
          <p>{`When you sign up for an annual membership, you receive two months free. That's a discount of more than 16%!`}</p>
        </HelpPopup></li>
      <li><HelpPopup title="How long is my commitment?" mdxType="HelpPopup">
          <p>{`We offer monthly and annual plans. You can cancel your membership at any time, effective at end of the current billing cycle.`}</p>
        </HelpPopup></li>
      <li><HelpPopup title="Are you trading your own strategies?" mdxType="HelpPopup">
          <p>{`Yes, we are! We believe in our portfolios, and especially the advantages of our `}<a parentName="p" {...{
              "href": "/articles/all-stars-portfolios/"
            }}>{`All-Stars family of portfolios`}</a>{`. That's why we confidently put our money where our mouth is.`}</p>
        </HelpPopup></li>
      <li><HelpPopup title="Can you give me advice?" mdxType="HelpPopup">
          <p>{`We are happy to help with any problems you might face while operating our platform, and we can help you understand how our portfolios work. However, please know that we cannot
provide any advice that is `}<em>{`personalized`}</em>{` to your financial situation. Read our disclaimer `}<a parentName="p" {...{
              "href": "/company/disclaimer/"
            }}>{`here`}</a>{`.`}</p>
        </HelpPopup></li>
      <li><HelpPopup title="My question isn't answered" mdxType="HelpPopup">
          <p>{`Our site offers a more complete FAQ and extensive step-by-step articles `}<a parentName="p" {...{
              "href": "/help/"
            }}>{`here`}</a>{`.`}</p>
        </HelpPopup></li>
    </ul>
    <h2>{`Invest Better. Risk Less. Profit More.`}</h2>
    <p>{`Free 14-day trial to access all premium features. No credit card required.`}</p>
    <SignUpButton mdxType="SignUpButton" />
    <br /><br /><br /><br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      